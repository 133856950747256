
import { defineComponent } from 'vue';
import Avatar from '@/components/avatars/Avatar.vue';
import UploadProfile from '@/views/new-design/pages/Profile/UploadProfileModal.vue';
import { mapActions, mapGetters } from 'vuex';
import {
  Getters as AuthGetters,
  Actions as AuthActions,
} from '@/store/enums/AuthEnums';
import { Getters } from '@/store/enums/StoreEnums';
import mask from '@/utils/mask';
import toasts from '@/utils/toasts';
import parser from '@/utils/parser';
import Navigator from '@/views/new-design/layout/Navigator.vue';
import text from '@/utils/text';
import { User } from '@/models/UserModel';

const initValue = {} as unknown as User;

export default defineComponent({
  name: 'profile-page',
  components: {
    Avatar,
    UploadProfile,
    Navigator,
  },
  data: () => ({
    currentUser: initValue,
  }),
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      defaultBackgroundColor: Getters.GET_DEFAULT_BACKGROUND_COLOR,
      defaultTextColor: Getters.GET_DEFAULT_TEXT_COLOR,
      defaultAvatarBackgroundColor: Getters.GET_DEFAULT_AVATAR_BGCOLOR,
      profilePicture: AuthGetters.GET_AUTH_USER_PROFILE_PICTURE,
      actionErrors: AuthGetters.GET_AUTH_USER_ACTION_ERROR,
    }),

    mobileMasking() {
      return mask.mobile.aus;
    },

    userRole() {
      const userRoles = this.authUser?.roles;

      return parser.userRole(userRoles);
    },

    businessBackgroundColor() {
      return this.authUser?.business_color;
    },

    businessTextColor() {
      return this.authUser?.business_text_color;
    },

    agentAvatarSrc() {
      return this.authUser?.avatar_src;
    },

    agentAcronym() {
      const firstName = (this.authUser || {})?.first_name;
      const lastName = (this.authUser || {})?.last_name;

      if (firstName === '' && lastName === '')
        return text.acronym((this.authUser || {})?.email);

      return text.acronym(`${firstName} ${lastName}`);
    },

    mobileNumber() {
      return text.formatMobile(this.authUser.mobile_number)
    }
  },

  methods: {
    ...mapActions({
      uploadProfilePicture: AuthActions.UPLOAD_PROFILE_PICTURE,
      removeAvatar: AuthActions.REMOVE_PROFILE_PICTURE,
    }),
    canAccess() {
      return this.authUser?.roles.includes('principal');
    },
    handleUpload(values) {
      if (!values || values == '') {
        return;
      }

      const accepted = ['image/png', 'image/gif', 'image/jpeg'];

      if (!accepted.includes(values.type)) {
        toasts.error({}, 'Invalid file type.');
        return false;
      }

      this.uploadProfilePicture(values)
        .then(async () => {
          toasts.success('Profile Successfully Updated');
        })
        .catch(() => {
          const { errors, message } = this.actionErrors;
          toasts.error(errors, message);
        });
    },
    async handleRemoveAvatar() {
      await this.removeAvatar().catch(() => {
        const { errors, message } = this.actionErrors;
        toasts.error(errors, message);
      });
    },
    removeSpaces(value) {
      return text.removeSpaces(value);
    },
  },

  watch: {
    authUser(values) {
      this.currentUser = values;
    },
  },
});
